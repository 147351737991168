import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import useHandleShareDocumentWith from '../../../hooks/useHandleShareDocumentWith'
import useItemFilter from '../../../hooks/UseItemFilter'
import useUser from '../../../hooks/useUser'
import { IDocument } from '../../../interfaces/document'
import RadioItem from '../../dropdownmenu/RadioItem'
import SubMenuContent from '../../dropdownmenu/SubMenuContent'
import SubMenuTrigger from '../../dropdownmenu/SubMenuTrigger'
import PlayerPermissions from './PlayerPermissions'

type Props = {
	document: IDocument
}

const Permissions = ({ document }: Props) => {
	const { isGM } = useUser()
	const { access } = document
	const { checkAccess } = useItemFilter()
	const hasAccess = checkAccess(document)
	const { handleSharePublic, handleSharePrivate } = useHandleShareDocumentWith()

	const handleAccessChange = (value: string) => {
		if (value === 'private') {
			handleSharePrivate(document._id)
		} else {
			handleSharePublic(document._id)
		}
	}

	if (!hasAccess && !isGM) return null

	return (
		<>
			<DropdownMenu.Sub>
				<SubMenuTrigger>Share</SubMenuTrigger>

				<SubMenuContent>
					<DropdownMenu.RadioGroup
						value={access}
						onValueChange={handleAccessChange}
					>
						<RadioItem value={'private'} disabled={!isGM}>
							Private
						</RadioItem>
						<RadioItem value={'public'}>Public</RadioItem>
					</DropdownMenu.RadioGroup>

					<PlayerPermissions document={document} />
				</SubMenuContent>
			</DropdownMenu.Sub>
		</>
	)
}

export default Permissions
