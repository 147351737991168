import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import GameContext from '../../contexts/game'
import UsersContext from '../../contexts/users'
import useGetLog from '../../hooks/useGetLog'
import useUser from '../../hooks/useUser'
import Button from '../FormComponents/Button'
import Message from './Message'
import useHandleDrop from './useHandleDrop'

const ITEMS_PER_PAGE = 20

const Log = () => {
	const { dispatch } = useContext(GameContext)
	const { usersState } = useContext(UsersContext)
	const { userId } = useUser()
	const { log } = useGetLog()
	const [visibleItems, setVisibleItems] = useState(ITEMS_PER_PAGE)
	const dropRef = useRef<HTMLDivElement>(null)
	const logContainerRef = useRef<HTMLDivElement>(null)
	const [isAtBottom, setIsAtBottom] = useState(true)
	const user = useMemo(
		() => usersState.users.find(u => u.userId === userId),
		[usersState.users, userId],
	)
	const drop = useHandleDrop({ userId, userProfile: user?.userProfile })

	const handleDelete = useCallback(
		(messageId: string) => {
			dispatch({ type: 'DELETE_MESSAGE', payload: messageId })
		},
		[dispatch],
	)

	const loadMore = useCallback(() => {
		const currentScrollHeight = logContainerRef.current?.scrollHeight || 0
		setVisibleItems(prev => Math.min(prev + ITEMS_PER_PAGE, log.length))

		// Maintain scroll position after loading more items
		setTimeout(() => {
			if (logContainerRef.current) {
				const newScrollHeight = logContainerRef.current.scrollHeight
				logContainerRef.current.scrollTop =
					newScrollHeight - currentScrollHeight
			}
		}, 0)
	}, [log.length])

	const checkIfAtBottom = useCallback(() => {
		if (logContainerRef.current) {
			const { scrollTop, scrollHeight, clientHeight } = logContainerRef.current
			setIsAtBottom(scrollHeight - scrollTop <= clientHeight + 1)
		}
	}, [])

	useEffect(() => {
		const logContainer = logContainerRef.current
		if (logContainer) {
			logContainer.addEventListener('scroll', checkIfAtBottom)
			return () => logContainer.removeEventListener('scroll', checkIfAtBottom)
		}
	}, [checkIfAtBottom])

	useEffect(() => {
		if (isAtBottom && logContainerRef.current) {
			logContainerRef.current.scrollTop = logContainerRef.current.scrollHeight
		}
	}, [log.length, isAtBottom])

	drop(dropRef)

	const visibleLog = log.slice(-visibleItems)

	return (
		<div
			ref={node => {
				dropRef.current = node
				logContainerRef.current = node
			}}
			className='-mx-3 -mt-6 flex h-full flex-1 flex-col-reverse overflow-y-auto py-2 px-3'
		>
			<div>
				{visibleLog.map((message, index) => {
					const prevMessage = index > 0 ? visibleLog[index - 1] : null
					const newSender =
						!prevMessage || prevMessage.sender !== message.sender
					const newAssumedCharacter =
						!prevMessage ||
						prevMessage.assumedCharacterId !== message.assumedCharacterId
					const showSender = newSender || newAssumedCharacter

					return (
						<Message
							key={message._id}
							message={message}
							onDelete={() => handleDelete(message._id)}
							showSender={showSender}
						/>
					)
				})}
			</div>
			{visibleItems < log.length && (
				<div className='py-8 text-center'>
					<Button
						onClick={loadMore}
						className='m-auto inline-block w-auto rounded-full'
					>
						Load More...
					</Button>
				</div>
			)}
		</div>
	)
}

export default React.memo(Log)
