import { BugAntIcon } from '@heroicons/react/24/solid'
import { useContext } from 'react'
import GameContext from '../contexts/game'
import useUser from '../hooks/useUser'
import ToolButton from './interface/ToolButton'

export default function LogGame() {
	const { game } = useContext(GameContext)
	const { isGM } = useUser()

	if (!isGM) return null

	const handleClick = () => {
		console.log('Game:', game._id, game)
	}

	return (
		<button onClick={handleClick}>
			<ToolButton label='Log Game' className='mt-4'>
				<BugAntIcon className='h-6 w-6' aria-hidden='true' />
			</ToolButton>
		</button>
	)
}
