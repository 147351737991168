import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'
import GameContext from '../../contexts/game'
import useCollectionTypes from '../../hooks/useCollectionTypes'
import useRemoteRequestToOpenDocument from '../../hooks/useRemoteRequestToOpenDocument'
import useUser from '../../hooks/useUser'
import Book from '../books/Book'
import ListMessage from '../views/ListMessage'
import DocumentTypeList from './DocumentTypeList'
import List from './List'
import useUnpop from './useUnpop'

export type THoverItem = (
	dragIndexPath: number[],
	hoverIndexPath: number[],
) => void

export type TDragItem = {
	id: string
	indexPath: number[]
	children?: TDragItem[]
}

const Library = () => {
	const { game, dispatch } = useContext(GameContext)
	const { _id: gameId, documents, books } = game
	const { control, setValue } = useFormContext()
	const [dragIndex, setDragIndex] = useState(-1)
	const [hoverIndex, setHoverIndex] = useState(-1)
	const { userId, role } = useUser()
	const collectionTypes = useCollectionTypes()
	useUnpop()
	useRemoteRequestToOpenDocument()

	const storageKey = `selectedType_${gameId}`
	const storedType = localStorage.getItem(storageKey)

	useEffect(() => {
		if (storedType) {
			setValue('selection', storedType)
		}
	}, [setValue, storedType])

	const selectedType = useWatch({
		control,
		name: 'selection',
		defaultValue: storedType || collectionTypes[0],
	})

	useEffect(() => {
		localStorage.setItem(storageKey, selectedType)
	}, [selectedType, storageKey])

	function moveItem({ id, targetId, parentId, before = true }) {
		const documentIndex = documents.allIds.indexOf(id)
		const targetIndex = documents.allIds.indexOf(targetId)
		if (documentIndex < 0 || targetIndex < 0) {
			console.error('Document or target not found!')
			return
		}
		const newAllIds = [...documents.allIds]
		newAllIds.splice(documentIndex, 1)
		const newIndex = before ? targetIndex : targetIndex + 1
		newAllIds.splice(newIndex, 0, id)
		dispatch({
			type: 'REORDER_DOCUMENTS',
			payload: {
				documentIds: newAllIds, // the reordered document IDs
				documentId: id, // the ID of the document that was moved
				parentId, // the new parentId of the moved document
			},
		})
	}

	const hoverItem = (
		dragId: string,
		hoverId: string,
		before?: 'up' | 'down',
	) => {
		const dragIndex = documents.allIds.findIndex(id => id === dragId)
		const hoverIndex = documents.allIds.findIndex(id => id === hoverId)

		setDragIndex(dragIndex)
		setHoverIndex(before === 'up' ? hoverIndex : hoverIndex + 1)
	}

	// Refactored: Created a reusable function for access checking
	const hasAccess = useCallback(
		items => {
			const isGM = role === 'gm'
			return items.allIds.some(itemId => {
				const item = items.byId[itemId]
				const { access, accessList } = item
				const isPublic = access === 'public'
				return isGM || isPublic || accessList?.includes(userId)
			})
		},
		[role, userId],
	)

	const hasAccessToDocuments = useMemo(
		() => hasAccess(documents),
		[documents, hasAccess],
	)

	const hasAccessToBooks = useMemo(() => hasAccess(books), [books, hasAccess])

	return (
		<>
			<DocumentTypeList />

			{selectedType === 'Books' ? (
				<ul
					className={twMerge(
						'grid grid-flow-row overflow-auto',
						hasAccessToBooks && 'grid-cols-2',
					)}
				>
					{!hasAccessToBooks ? (
						<ListMessage title='No Books' />
					) : (
						books.allIds.map(bookId => <Book key={bookId} bookId={bookId} />)
					)}
				</ul>
			) : (
				<>
					{!hasAccessToDocuments ? (
						<ListMessage title='No Documents' />
					) : (
						<List
							parentId='library'
							dragIndex={dragIndex}
							hoverIndex={hoverIndex}
							hoverItem={hoverItem}
							moveItem={moveItem}
							selectedType={selectedType}
						/>
					)}
				</>
			)}
		</>
	)
}

export default Library
