import { useContext, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import GameContext from '../../contexts/game'
import UsersContext from '../../contexts/users'
import useTheme from '../../hooks/useTheme'
import useUser from '../../hooks/useUser'
import MediumUppercase from '../text/MediumUppercase'

type UserPillProps = {
	userId: string
	color?: string
	className?: string
}

const UserPill: React.FC<UserPillProps> = ({ userId, className }) => {
	const { game } = useContext(GameContext)
	const { usersState } = useContext(UsersContext)
	const { primary, secondary } = useTheme()
	const { userId: thisUserId } = useUser()
	const user = usersState.users.find(u => u.userId === userId)
	const [userName, setUserName] = useState<string>('')
	const [characterName, setCharacterName] = useState<string>('')

	useEffect(() => {
		if (!user) return

		const name = user.userProfile.name.split(' ')[0]
		setUserName(userId === thisUserId ? 'You' : name)

		const assumedCharacterId = user.userSettings?.assumedCharacterId

		if (assumedCharacterId) {
			const assumedCharacter = game.documents.byId[assumedCharacterId]

			if (assumedCharacter?.values?.name) {
				setCharacterName(assumedCharacter?.values?.name?.split(' ')[0])
			}
		} else {
			setCharacterName('')
		}
	}, [
		user?.userSettings?.assumedCharacterId,
		game.documents,
		userId,
		thisUserId,
	])

	return (
		<div
			className={twMerge(
				'mt-1 w-full text-center transition-opacity duration-200 ease-in-out',
				className,
			)}
		>
			<MediumUppercase>
				<div
					className='max-w-4/5'
					style={{
						display: '-webkit-box',
						WebkitBoxOrient: 'vertical',
						WebkitLineClamp: 2,
						overflow: 'hidden',
						color: primary,
						textShadow: '0 1px 2px rgba(0, 0, 0, 0.8)',
					}}
				>
					<p>{characterName}</p>
					<p
						style={{
							color: secondary,
							textShadow: '0 1px 2px rgba(0, 0, 0, 0.8)',
						}}
					>
						{userName}
					</p>
				</div>
			</MediumUppercase>
		</div>
	)
}

export default UserPill
