// This component is used to listen to socket events from users
// keeping track of who is online, and socket to user id mapping
import { useContext, useEffect } from 'react'
import {
	SocketsInRoomMessage,
	WhoIAmMessage,
} from '../../../shared/types/socket'
import { socket } from '../contexts/socket'
import UsersContext from '../contexts/users'
import { IRegisteredUser } from '../interfaces/users'
import useUser from './useUser'

const useUsersSocketListeners = () => {
	const { userId } = useUser()
	const { usersState, dispatchUsers } = useContext(UsersContext)

	const socketSetup = () => {
		const handleWhoIAm = (userData: WhoIAmMessage) => {
			const { userId, socketId } = userData

			if (!userId || !socketId) {
				console.warn('WARNING userId or socketId missing', userData)
			}

			const user = usersState.users.find(u => u.userId === userId)
			if (user?.socketId === socketId) return

			dispatchUsers({
				type: 'UPDATE_SOCKET_USER_CONNECTION',
				payload: {
					userId,
					socketId,
				},
			})
		}

		const updatePlayersOnlineStatuses = (message: SocketsInRoomMessage) => {
			const { socketIds } = message
			if (!socketIds || !Array.isArray(socketIds)) {
				console.warn('Invalid socketIds received:', socketIds)
				return
			}

			const newUsers = usersState.users.map((u: IRegisteredUser) => {
				const socketIsOnline = socketIds.includes(u.socketId)
				const userIsMe = u.userId === userId
				const isOnline = socketIsOnline || userIsMe
				return { ...u, online: isOnline }
			})

			const same = JSON.stringify(newUsers) === JSON.stringify(usersState.users)

			if (!same) {
				dispatchUsers({
					type: 'UPDATE_USERS',
					payload: { users: newUsers },
				})
			}
		}

		socket.on('who i am', handleWhoIAm)
		socket.on('sockets in room', updatePlayersOnlineStatuses)

		return () => {
			socket.off('who i am', handleWhoIAm)
			socket.off('sockets in room', updatePlayersOnlineStatuses)
		}
	}

	useEffect(socketSetup, [usersState])
}

export default useUsersSocketListeners
