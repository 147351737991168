import React from 'react'
import { twMerge } from 'tailwind-merge'
import useTheme from '../../hooks/useTheme'
import MediumUppercase from '../text/MediumUppercase'
import SmallUppercase from '../text/SmallUppercase'
import ToolbarControlButton from '../toolbar/ControlButton'
import Translucency from '../Translucency'

import { ReactComponent as D10Icon } from '../../assets/dice-icons/d10.svg'
import { ReactComponent as D12Icon } from '../../assets/dice-icons/d12.svg'
import { ReactComponent as D20Icon } from '../../assets/dice-icons/d20.svg'
import { ReactComponent as D4Icon } from '../../assets/dice-icons/d4.svg'
import { ReactComponent as D6Icon } from '../../assets/dice-icons/d6.svg'
import { ReactComponent as D8Icon } from '../../assets/dice-icons/d8.svg'
import useUser from '../../hooks/useUser'

const diceIcons: {
	[key: string]: React.ComponentType<React.SVGProps<SVGSVGElement>>
} = {
	d4: D4Icon,
	d6: D6Icon,
	d8: D8Icon,
	d10: D10Icon,
	d12: D12Icon,
	d20: D20Icon,
}

interface AdvRollBtnProps {
	label: string
	tooltip?: string
	onRoll: (notation: string) => void
	notation: string
	disabled?: boolean
	icons?: string[]
}

const AdvRollBtn: React.FC<AdvRollBtnProps> = ({
	label,
	tooltip,
	onRoll,
	notation,
	disabled = false,
	icons,
}) => {
	const { primary } = useTheme()
	const { color } = useUser()
	const roll = () => {
		if (!disabled) {
			onRoll(notation)
		}
	}

	const renderContent = () => {
		if (icons && icons.length > 0) {
			return (
				<div className='flex items-center justify-center'>
					{icons.map((icon, index) => {
						const IconComponent = diceIcons[icon]
						return IconComponent ? (
							<IconComponent
								key={index}
								className='h-6 w-6'
								style={{ color }} // Set the color here
							/>
						) : null
					})}
				</div>
			)
		} else {
			return label.length > 7 ? (
				<SmallUppercase style={{ color: primary }}>{label}</SmallUppercase>
			) : (
				<MediumUppercase>{label}</MediumUppercase>
			)
		}
	}

	return (
		<ToolbarControlButton
			className={twMerge(
				'h-10 w-20 overflow-hidden rounded-full text-gray-700',
				Translucency,
				disabled && 'cursor-not-allowed opacity-50',
			)}
			innerStyle={{
				color: primary,
			}}
			innerClassName='px-3'
			onClick={roll}
			label={tooltip || label}
			disabled={disabled}
		>
			{renderContent()}
		</ToolbarControlButton>
	)
}

export default AdvRollBtn
