import { QueueListIcon } from '@heroicons/react/24/solid'
import { useContext } from 'react'
import GameContext from '../../contexts/game'
import useTheme from '../../hooks/useTheme'
import useUser from '../../hooks/useUser'
import ToolButton from '../interface/ToolButton'

export default function TurnTrackerButton() {
	const { game, dispatch } = useContext(GameContext)
	const { primary } = useTheme()
	const { isGM } = useUser()
	const isShowing = game.turnTracker.show
	const isRunning = game.turnTracker.currentRound > 0

	if (!isGM) return null

	const handleToggleTurnTracker = () => {
		if (isShowing) {
			if (isRunning) {
				const confirmHide = window.confirm(
					'Hiding the turn tracker will stop it and reset the round counter. Are you sure?',
				)
				if (!confirmHide) return
			}
			dispatch({ type: 'HIDE_TURN_TRACKER' })
			if (isRunning) {
				dispatch({ type: 'STOP_TURN_TRACKER' })
			}
		} else {
			dispatch({ type: 'SHOW_TURN_TRACKER' })
			// dispatch({ type: 'HIDE_LIBRARY' })
			// dispatch({ type: 'HIDE_BOOKS' })
			// dispatch({ type: 'HIDE_SYRINSCAPE' })
		}
	}

	return (
		<button onClick={handleToggleTurnTracker}>
			<ToolButton className='mt-4' label={'Turn Tracker'} isOpen={isShowing}>
				<QueueListIcon
					className='h-5 w-5'
					aria-hidden='true'
					style={{
						color: isShowing ? 'black' : primary,
					}}
				/>
			</ToolButton>
		</button>
	)
}
