import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/24/solid'
import { forwardRef, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import useTheme from '../hooks/useTheme'
import Tooltip from './Tooltip'
import Translucency from './Translucency'

interface StepperProps {
	value: number
	onChange: (value: number) => void
	min?: number
	max?: number
	step?: number
	className?: string
	tooltip?: string
}

const Stepper = forwardRef<HTMLDivElement, StepperProps>(
	(
		{
			value,
			onChange,
			min = -Infinity,
			max = Infinity,
			step = 1,
			className,
			tooltip,
		},
		ref,
	) => {
		const { primary } = useTheme()
		const [isFocused, setIsFocused] = useState(false)
		const [isHovered, setIsHovered] = useState(false)
		const stepperRef = useRef<HTMLDivElement>(null)

		const increment = () => {
			const newValue = Math.min(value + step, max)
			onChange(newValue)
		}

		const decrement = () => {
			const newValue = Math.max(value - step, min)
			onChange(newValue)
		}

		const displayValue = isFocused
			? value.toString()
			: value > 0
			? `+${value}`
			: value.toString()

		const stepperContent = (
			<div
				ref={node => {
					// Forward the ref to the outer div
					if (typeof ref === 'function') {
						ref(node)
					} else if (ref) {
						ref.current = node
					}
					stepperRef.current = node
				}}
				className={twMerge(
					'flex h-10 items-center rounded-full bg-gray-800 px-1 text-xs text-gray-700',
					Translucency,
					className,
				)}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
			>
				<button
					onClick={decrement}
					className='flex h-8 items-center justify-center rounded-full opacity-75 transition-opacity hover:opacity-100'
					style={{
						color: primary,
					}}
				>
					<MinusCircleIcon className='h-8 w-8' />
				</button>
				<input
					type='text'
					value={displayValue}
					onChange={e => {
						const newValue = e.target.value.replace(/^(\+?)/, '')
						onChange(Number(newValue))
					}}
					onFocus={() => setIsFocused(true)}
					onBlur={() => setIsFocused(false)}
					className='h-full w-8 bg-transparent text-center text-sm text-white focus:outline-none'
					style={{
						color: primary,
					}}
				/>
				<button
					onClick={increment}
					className='flex h-8 items-center justify-center rounded-full opacity-75 transition-opacity hover:opacity-100'
					style={{
						color: primary,
					}}
				>
					<PlusCircleIcon className='h-8 w-8' />
				</button>
			</div>
		)

		return (
			<>
				{tooltip ? (
					<Tooltip open={isHovered} anchor={stepperRef} placement='top'>
						{tooltip}
					</Tooltip>
				) : null}
				{stepperContent}
			</>
		)
	},
)

Stepper.displayName = 'Stepper'

export default Stepper
