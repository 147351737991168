import { UserPlusIcon } from '@heroicons/react/24/solid'
import { useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import useUser from '../../hooks/useUser'
import InvitePlayerPopover from './InvitePlayerPopover'
import ToolButton from './ToolButton'

export default function AddPlayerButtonSmall() {
	const [isOpen, setIsOpen] = useState(false)
	const { isGM } = useUser()
	const buttonRef = useRef<HTMLButtonElement>(null)

	if (!isGM) return null

	return (
		<>
			<button ref={buttonRef} onClick={() => setIsOpen(true)}>
				<ToolButton className='mt-4' label={'Invite'} isOpen={isOpen}>
					<UserPlusIcon className='h-6 w-6' aria-hidden='true' />
				</ToolButton>
			</button>

			{isOpen &&
				createPortal(
					<InvitePlayerPopover
						isOpen={isOpen}
						setIsOpen={setIsOpen}
						anchorRef={buttonRef}
					/>,
					document.body,
				)}
		</>
	)
}
