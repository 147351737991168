import { useQuery } from '@apollo/client' // Import useQuery
import { useRef, useState } from 'react' // Import useState and useRef
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { GAME_USERS } from '../../../graphql/games' // Import GAME_USERS
import { IGameCard } from '../../../interfaces/game'
import { IUserProfile } from '../../../interfaces/users'
import Avatar from '../../Avatar'
import GameMenu from '../../gamemenu/GameMenu'
import DocumentWindowMenuButton from '../../Library/MenuButton'
import Overlapper from '../../Overlapper'
import Tooltip from '../../Tooltip'
import GameCard from './GameCard'

export interface IGameCardContainerProps {
	game: IGameCard
	refetch?: () => void
	className?: string
}

export default function GameCardContainer({
	game,
	refetch,
	className,
}: IGameCardContainerProps) {
	const [isHovered, setIsHovered] = useState(false)

	const { data, loading, error } = useQuery(GAME_USERS, {
		variables: {
			gameId: game._id,
		},
	})

	// Inline component definition
	const UserAvatarWithTooltip = ({ user }: { user: IUserProfile }) => {
		const avatarRef = useRef<HTMLDivElement>(null)
		const [showTooltip, setShowTooltip] = useState(false)

		return (
			<div
				key={user._id}
				ref={avatarRef}
				onMouseEnter={() => setShowTooltip(true)}
				onMouseLeave={() => setShowTooltip(false)}
			>
				<Tooltip open={showTooltip} anchor={avatarRef} placement='bottom'>
					{user.name}
				</Tooltip>
				<Avatar
					src={user.avatar}
					userId={user._id}
					userName={user.name}
					className='mr-2'
					style={{ boxShadow: '0 2px 10px 0 rgba(0,0,0,0.2)' }}
				/>
			</div>
		)
	}

	return (
		<div
			className={twMerge('rounded-xl bg-black/10', className)}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			<div className='relative'>
				<GameMenu game={game} refetch={refetch} show={isHovered}>
					<DocumentWindowMenuButton show={true} />
				</GameMenu>

				<Link key={game._id} to={'/game/' + game._id}>
					<GameCard game={game} />
				</Link>
			</div>

			<div className='flex flex-row items-center justify-between'>
				<div className='flex flex-col p-3 px-3 pt-0'>
					<p className='mt-2 font-medium'>{game.title}</p>
					<p className=' text-xs text-gray-500'>
						{game.system?.name || 'No Game System'}
					</p>
				</div>

				<Overlapper className='w-1/2' align='right'>
					{loading && (
						<div className='flex items-center justify-center p-3'>loading</div>
					)}
					{error && (
						<div className='flex items-center justify-center p-3'>error</div>
					)}
					{data?.gameUsers?.map((user: IUserProfile) => (
						<UserAvatarWithTooltip key={user._id} user={user} />
					))}
				</Overlapper>
			</div>
		</div>
	)
}
