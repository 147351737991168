import React, {
	memo,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react'
import PdfViewerContext from '../../contexts/book'
import PdfPage from './PdfPage'
import { PriorityQueue } from './utils/PriorityQueue'

type Props = {
	containerRef: React.RefObject<HTMLDivElement>
	pageSizes: { width: number; height: number }[]
}

const DEBUG_MODE = true
const MAX_CONCURRENT_RENDERING = 2

function RenderPages({ containerRef, pageSizes }: Props) {
	const { bookState, bookDispatch } = useContext(PdfViewerContext)
	const {
		fitWidth,
		fitPage,
		zoomLevel,
		numPages,
		pagesToDisplay,
		isCoverAlone,
	} = bookState
	const [renderedPages, setRenderedPages] = useState<Set<number>>(new Set())
	const renderedPagesRef = useRef<Set<number>>(new Set())
	const [currentIndex, setCurrentIndex] = useState(0)
	const renderQueueRef = useRef(new PriorityQueue<number>())
	const observerRef = useRef<IntersectionObserver | null>(null)
	const [currentlyRendering, setCurrentlyRendering] = useState<Set<number>>(
		new Set(),
	)
	const currentlyRenderingRef = useRef<Set<number>>(new Set())

	useEffect(() => {
		renderedPagesRef.current = renderedPages
	}, [renderedPages])

	useEffect(() => {
		currentlyRenderingRef.current = currentlyRendering
	}, [currentlyRendering])

	const calculatePageDimensions = useCallback(
		(pageIndex: number) => {
			const parentRect = containerRef.current?.getBoundingClientRect()
			const containerWidth = parentRect?.width || 0
			const containerHeight = parentRect?.height || 0

			const { width: pageWidth, height: pageHeight } = pageSizes[pageIndex]

			// Calculate the dimensions based on fit options and zoom level
			let width = pageWidth * zoomLevel
			let height = pageHeight * zoomLevel

			if (fitWidth) {
				width = containerWidth / pagesToDisplay
				height = (width / pageWidth) * pageHeight
			} else if (fitPage) {
				let availableWidth = containerWidth
				const availableHeight = containerHeight

				if (pagesToDisplay === 2) {
					availableWidth /= 2
				}

				const scaleWidth = availableWidth / pageWidth
				const scaleHeight = availableHeight / pageHeight
				const scale = Math.min(scaleWidth, scaleHeight)
				width = pageWidth * scale
				height = pageHeight * scale
			}

			// Round the final sizes to whole numbers
			return {
				width: Math.round(width),
				height: Math.round(height),
			}
		},
		[pageSizes, containerRef, fitWidth, fitPage, zoomLevel, pagesToDisplay],
	)

	const queueAllUnrenderedPages = useCallback(() => {
		for (let i = 1; i <= numPages; i++) {
			if (!renderedPagesRef.current.has(i)) {
				// Lower priority number for pages closer to the current index
				const priority = Math.abs(i - (currentIndex + 1))
				if (!renderQueueRef.current.includes(i)) {
					renderQueueRef.current.enqueue(i, priority)
					// DEBUG_MODE &&  console.log(`[Queue] Enqueued page ${i} with priority ${priority}`)
				}
			}
		}
	}, [numPages, currentIndex])

	const updatePriorities = useCallback(() => {
		const newQueue = new PriorityQueue<number>()
		while (!renderQueueRef.current.isEmpty()) {
			const page = renderQueueRef.current.dequeue()
			if (page !== undefined) {
				// Lower priority number for pages closer to the current index
				const priority = Math.abs(page - (currentIndex + 1))
				newQueue.enqueue(page, priority)
				// DEBUG_MODE &&  console.log(
				// `[Queue] Updated page ${page} with new priority ${priority}`,
				// )
			}
		}
		renderQueueRef.current = newQueue
	}, [currentIndex])

	const processRenderQueue = useCallback(() => {
		console.log(
			'[RENDER] PROCESSING RENDER QUEUE',
			currentlyRenderingRef.current.size < MAX_CONCURRENT_RENDERING,
			!renderQueueRef.current.isEmpty(),
		)
		if (
			currentlyRenderingRef.current.size < MAX_CONCURRENT_RENDERING &&
			!renderQueueRef.current.isEmpty()
		) {
			const page = renderQueueRef.current.dequeue()
			if (page !== undefined) {
				DEBUG_MODE &&
					console.log(
						`[Render] Processing page: ${page}`,
						renderedPagesRef.current,
						currentlyRenderingRef.current.size,
					)
				if (
					!renderedPagesRef.current.has(page) &&
					!currentlyRenderingRef.current.has(page)
				) {
					DEBUG_MODE && console.log(`[Render] Starting to render page: ${page}`)
					setCurrentlyRendering(prev => {
						const next = new Set(prev).add(page)
						currentlyRenderingRef.current = next
						return next
					})
					if (DEBUG_MODE) {
						console.log(`[Render] Started rendering page: ${page}`)
					}
				} else {
					// If the page is already rendered or rendering, process the next page
					DEBUG_MODE &&
						console.log('[Render] Page already rendered or rendering')
					processRenderQueue()
				}
			}
		} else {
			DEBUG_MODE &&
				console.log(
					'[Render] No pages to render',
					currentlyRenderingRef.current,
					renderQueueRef.current,
				)
		}
	}, [])

	const handleRenderSuccess = useCallback(
		(pageNumber: number) => {
			console.log(`[Render] Finished rendering page: ${pageNumber}`)
			setCurrentlyRendering(prev => {
				const next = new Set(prev)
				next.delete(pageNumber)
				currentlyRenderingRef.current = next
				return next
			})
			setRenderedPages(prev => {
				const next = new Set(prev).add(pageNumber)
				renderedPagesRef.current = next
				return next
			})
			if (DEBUG_MODE) {
				console.log(`[Render] Finished rendering page: ${pageNumber}`)
			}
			processRenderQueue()
		},
		[processRenderQueue],
	)

	useEffect(() => {
		queueAllUnrenderedPages()
		processRenderQueue()
	}, [queueAllUnrenderedPages, processRenderQueue])

	useEffect(() => {
		updatePriorities()
		processRenderQueue()
	}, [currentIndex, updatePriorities, processRenderQueue])

	// Add a new effect to check and resume rendering if it stops
	useEffect(() => {
		const checkRenderingStatus = () => {
			if (
				currentlyRenderingRef.current.size === 0 &&
				!renderQueueRef.current.isEmpty()
			) {
				console.log('[Render] Resuming rendering process')
				processRenderQueue()
			}
		}

		const intervalId = setInterval(checkRenderingStatus, 1000)

		return () => clearInterval(intervalId)
	}, [processRenderQueue])

	const handleKeyDown = useCallback(
		(event: KeyboardEvent) => {
			if (!containerRef.current) return
			const { clientHeight } = containerRef.current
			const pagesPerView = Math.floor(
				clientHeight / calculatePageDimensions(0).height,
			)

			let newIndex = currentIndex
			if (event.key === 'ArrowRight' || event.key === 'PageDown') {
				event.preventDefault()
				newIndex = Math.min(currentIndex + pagesPerView, numPages - 1)
			} else if (event.key === 'ArrowLeft' || event.key === 'PageUp') {
				event.preventDefault()
				newIndex = Math.max(currentIndex - 1 - pagesPerView, 0)
			}

			if (newIndex !== currentIndex) {
				const nextElement = document.getElementById(`page_${newIndex + 1}`)
				nextElement?.scrollIntoView({ behavior: 'auto' })
				setCurrentIndex(newIndex)
				queueAllUnrenderedPages()
				updatePriorities()
				processRenderQueue()
			}
		},
		[
			currentIndex,
			numPages,
			calculatePageDimensions,
			queueAllUnrenderedPages,
			updatePriorities,
			processRenderQueue,
			containerRef, // Add containerRef to the dependency array
		],
	)

	const renderPages = useCallback(() => {
		const pages = []

		// Render cover page alone if isCoverAlone is true
		if (isCoverAlone && numPages > 0) {
			pages.push(
				<div
					key='row_0'
					className='pdf-page-container mb-px flex items-center justify-center'
				>
					<div key='page_1' id='page_1' className='pdf-page'>
						{renderedPages.has(1) || currentlyRendering.has(1) ? (
							<PdfPage
								pageNumber={1}
								calculatedSize={calculatePageDimensions(0)}
								onRenderSuccess={handleRenderSuccess}
							/>
						) : (
							<div style={calculatePageDimensions(0)}>Loading page 1...</div>
						)}
					</div>
				</div>,
			)
		}

		// Render the rest of the pages
		const startPage = isCoverAlone ? 2 : 1
		for (let i = startPage; i <= numPages; i += pagesToDisplay) {
			const pageNumbers = Array.from(
				{ length: pagesToDisplay },
				(_, index) => i + index,
			).filter(pageNum => pageNum <= numPages)

			pages.push(
				<div
					key={`row_${i}`}
					className='pdf-page-container mb-px flex items-center justify-center'
				>
					{pageNumbers.map(pageNumber => (
						<div
							key={`page_${pageNumber}`}
							id={`page_${pageNumber}`}
							className='pdf-page'
							ref={el => el && observerRef.current?.observe(el)}
						>
							{renderedPages.has(pageNumber) ||
							currentlyRendering.has(pageNumber) ? (
								<PdfPage
									pageNumber={pageNumber}
									calculatedSize={calculatePageDimensions(pageNumber - 1)}
									onRenderSuccess={() => handleRenderSuccess(pageNumber)}
								/>
							) : (
								<div style={calculatePageDimensions(pageNumber - 1)}>
									Loading page {pageNumber}...
								</div>
							)}
						</div>
					))}
				</div>,
			)
		}

		return pages
	}, [
		numPages,
		pagesToDisplay,
		renderedPages,
		calculatePageDimensions,
		isCoverAlone,
		handleRenderSuccess,
		currentlyRendering,
	])

	useEffect(() => {
		const page = currentIndex + 1
		bookDispatch({ type: 'SET_CURRENT_PAGE', payload: page })
		const url = new URL(window.location.href)
		url.searchParams.set('page', page.toString())
		window.history.replaceState({}, '', url.toString())
	}, [bookDispatch, currentIndex])

	useEffect(() => {
		window.addEventListener('keydown', handleKeyDown)
		return () => window.removeEventListener('keydown', handleKeyDown)
	}, [handleKeyDown])

	useEffect(() => {
		observerRef.current = new IntersectionObserver(
			entries => {
				entries.forEach(entry => {
					if (entry.isIntersecting && entry.intersectionRatio > 0.5) {
						const pageNumber = parseInt(entry.target.id.split('_')[1], 10)
						setCurrentIndex(pageNumber - 1)
						updatePriorities()
					}
				})
			},
			{
				root: containerRef.current,
				threshold: Array.from({ length: 101 }, (_, i) => i / 100),
			},
		)

		const pageElements = containerRef.current?.querySelectorAll('.pdf-page')
		pageElements?.forEach(el => observerRef.current?.observe(el))

		return () => {
			observerRef.current?.disconnect()
		}
	}, [updatePriorities, containerRef])

	return (
		<div className='absolute inset-0 overflow-y-auto' ref={containerRef}>
			{renderPages()}
		</div>
	)
}

export default memo(RenderPages)
