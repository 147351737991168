import { FC, useContext } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import AuthContext from '../../../contexts/auth'
import Logo from '../../Logo'
import AccountLink from '../elements/AccountLink'
import Tabs from '../elements/Tabs'

function useGetParam() {
	return new URLSearchParams(useLocation().search)
}

interface IProps {
	loading: boolean
}

const Navigation: FC<IProps> = ({ loading }) => {
	const location = useLocation()
	const isNotFrontsite =
		location.pathname.includes('/game/') ||
		location.pathname.includes('/book/') ||
		location.pathname.includes('/document/')
	const getParam = useGetParam()
	const inviteToken = getParam.get('inviteToken')

	const onInviteScreen = !!inviteToken
	const { authState } = useContext(AuthContext)

	if (isNotFrontsite && !onInviteScreen) return null

	return (
		<header className='flex flex-wrap items-center px-8 py-12 font-semibold text-white md:p-16'>
			<NavLink to='/' className='flex items-center text-xl'>
				<Logo className='mr-4' />
				<div className='flex flex-col'>
					<p>New Realms</p>
				</div>
			</NavLink>

			<nav className='flex flex-1 items-center justify-end space-x-6'>
				{!loading && !authState.token && (
					<>
						<NavLink to='/signup'>Get Started</NavLink>
						<NavLink to='/signin'>Sign In</NavLink>
					</>
				)}
				{!loading && authState.token && (
					<>
						<Tabs />
						<AccountLink
							avatarSrc={authState.userDetails.avatar}
							userName={authState.userDetails.name}
						/>
					</>
				)}
			</nav>
		</header>
	)
}

export default Navigation
