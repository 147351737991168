import { useContext } from 'react'
import { useDrop } from 'react-dnd'
import { twMerge } from 'tailwind-merge'
import DocumentsContext from '../../contexts/documents'
import useItemFilter from '../../hooks/UseItemFilter'
import useGetAvatarSrc from '../../hooks/useGetAvatarSrc'
import useGetDocumentById from '../../hooks/useGetDocumentById'
import useHandleShareDocumentWith from '../../hooks/useHandleShareDocumentWith'
import useTheme from '../../hooks/useTheme'
import useUser from '../../hooks/useUser'
import { IRegisteredUser } from '../../interfaces/users'
import Avatar from '../Avatar'
import ItemTypes from '../draganddrop/ItemTypes'
import UserMenu from './UserMenu'
import UserPill from './UserPill'

type Props = {
	user: IRegisteredUser
	className?: string
	style?: React.CSSProperties
}

export default function UserHead({ user, className, style }: Props) {
	const { userId: currentUserId } = useUser()
	const { userId, userSettings } = user
	const isMe = user.userId === currentUserId

	const { dispatchDocuments } = useContext(DocumentsContext)
	const { handleShareWith } = useHandleShareDocumentWith()

	const { primary } = useTheme()
	const color = userSettings?.color

	const assumedCharacterId = userSettings?.assumedCharacterId
	const { document } = useGetDocumentById(assumedCharacterId)
	const { checkAccess } = useItemFilter()
	const hasAccessToAssumedCharacter = checkAccess(document)

	const avatarSrc = useGetAvatarSrc({ userId })

	const handleDrop = ({ id }) => {
		handleShareWith(id, userId)
	}

	const handleClick = () => {
		if (hasAccessToAssumedCharacter) {
			dispatchDocuments({
				type: 'OPEN_DOCUMENT',
				payload: { documentId: assumedCharacterId },
			})
		}
	}

	const [{ isOver }, drop] = useDrop({
		accept: [ItemTypes.DOCUMENT],
		drop: handleDrop,
		collect: monitor => ({
			isOver: !!monitor.isOver(),
		}),
	})

	return (
		<li
			ref={drop}
			className={twMerge(
				'relative flex flex-col space-y-2 transition-all duration-300 ease-in-out',
				assumedCharacterId && 'cursor-pointer',
				className,
			)}
			style={{ ...style, maxWidth: '32rem' }}
			onClick={handleClick}
		>
			<UserMenu userId={userId}>
				<Avatar
					src={avatarSrc}
					className='aspect-[3/4] h-24'
					color={color}
					style={{
						boxShadow: isOver && `0 0 0 4px ${primary}`,
						border: isMe && `2px solid ${primary}`,
					}}
					userId={userId}
				/>
				<UserPill userId={userId} color={color} />
			</UserMenu>
		</li>
	)
}
