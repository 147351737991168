import { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { JoinGameMessage, LeaveGameMessage } from '../../../shared/types/socket'
import GameContext from '../contexts/game'
import { socket } from '../contexts/socket'

const useSocketConnection = () => {
	const { game } = useContext(GameContext)
	const { gameId } = useParams()

	const handleDisconnect = () => {
		const message: JoinGameMessage = { gameId }
		socket.emit('join game', message)
	}

	const handleSocketSetup = () => {
		socket.on('disconnect', handleDisconnect)

		if (game._id) return

		const joinMessage: JoinGameMessage = { gameId }
		socket.emit('join game', joinMessage)

		return () => {
			const leaveMessage: LeaveGameMessage = { gameId }
			socket.emit('leave game', leaveMessage)
			socket.off('disconnect', handleDisconnect)
		}
	}

	useEffect(handleSocketSetup, [])
}

export default useSocketConnection
