import useUser from '../../hooks/useUser'
import ExitButton from '../ExitButton'
import LogGame from '../LogGame'
import SettingsButton from '../toolbar/SettingsButton'
import AddPlayerButtonSmall from './AddPlayerButtonSmall'

const LeftTools = () => {
	const { isGM } = useUser()

	return (
		<div className='pointer-events-auto flex justify-center space-x-1.5'>
			<ExitButton />
			<SettingsButton />
			<AddPlayerButtonSmall />
			{isGM && <LogGame />}
		</div>
	)
}

export default LeftTools
