import { CogIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import useUser from '../../hooks/useUser'
import ToolButton from '../interface/ToolButton'
import Settings from '../settings/Settings'

export default function SettingsButton() {
	const { isGM } = useUser()
	const [isOpen, setIsOpen] = useState(false)

	if (!isGM) return null

	return (
		<>
			<button onClick={() => setIsOpen(true)}>
				<ToolButton className='mt-4' label='Settings' isOpen={isOpen}>
					<CogIcon className='h-6 w-6' aria-hidden='true' />
				</ToolButton>
			</button>

			{isOpen &&
				createPortal(
					<Settings onClose={() => setIsOpen(false)} />,
					document.body,
				)}
		</>
	)
}
