import { FolderIcon } from '@heroicons/react/24/solid'
import { useContext } from 'react'
import GameContext from '../../contexts/game'
import useTheme from '../../hooks/useTheme'
import ToolButton from '../interface/ToolButton'

export default function LibraryButton() {
	const { game, dispatch } = useContext(GameContext)
	const { primary } = useTheme()
	const isShowing = game.library.show

	const handleToggleLibrary = () => {
		if (isShowing) {
			dispatch({ type: 'HIDE_LIBRARY' })
		} else {
			dispatch({ type: 'SHOW_LIBRARY' })
			// dispatch({ type: 'HIDE_BOOKS' })
			// dispatch({ type: 'HIDE_TURN_TRACKER' })
			// dispatch({ type: 'HIDE_SYRINSCAPE' })
		}
	}

	return (
		<button onClick={handleToggleLibrary}>
			<ToolButton className='mt-4' isOpen={isShowing} label={'Documents'}>
				<FolderIcon
					className='h-5 w-5'
					aria-hidden='true'
					style={{
						color: isShowing ? 'black' : primary,
					}}
				/>
			</ToolButton>
		</button>
	)
}
