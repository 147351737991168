import { ArrowDownIcon } from '@heroicons/react/24/solid'
import { useContext } from 'react'
import GameContext from '../../contexts/game'
import SectionButton from './SectionButton'

interface NextButtonsProps {
	disabled?: boolean
}

const NextButtons = ({ disabled }: NextButtonsProps) => {
	const { game, dispatch } = useContext(GameContext)
	const { currentRound, currentTurnIndex } = game.turnTracker
	const { turnOrder } = game.turnTracker

	const moveToNext = () => {
		const nextTurnIndex = (currentTurnIndex + 1) % turnOrder.allIds.length
		const nextPlayerId = turnOrder.allIds[nextTurnIndex]
		const { name } = turnOrder.byId[nextPlayerId]

		const isNewRound = nextTurnIndex === 0
		const newRound = currentRound + (isNewRound ? 1 : 0)

		let message = `It is ${name}'s turn.`
		if (isNewRound) {
			message = `Round ${newRound} begins. ${message}`
		}

		dispatch({
			type: 'ADD_SYSTEM_MESSAGE',
			payload: { message },
		})

		dispatch({ type: 'TURN_ORDER_NEXT' })
	}

	if (currentRound === 0) return null

	return (
		<SectionButton onClick={moveToNext} disabled={disabled}>
			<ArrowDownIcon className='h-4 w-4' />
		</SectionButton>
	)
}

export default NextButtons
