import { PowerIcon } from '@heroicons/react/24/solid'
import { NavLink } from 'react-router-dom'
import useTheme from '../hooks/useTheme'
import ToolButton from './interface/ToolButton'

export default function ExitButton() {
	const { primary } = useTheme()

	return (
		<NavLink to='/profile'>
			<ToolButton className='mt-4' label='Leave'>
				<PowerIcon
					className='h-6 w-6'
					aria-hidden='true'
					style={{
						color: primary,
					}}
				/>
			</ToolButton>
		</NavLink>
	)
}
