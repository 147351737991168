import { useContext, useMemo } from 'react'
import GameContext from '../contexts/game'
import { IDocument } from '../interfaces/document'
import useUser from './useUser'

interface CollectionTypeInfo {
	type: string
	pluralName: string
}

const useCollectionTypes = () => {
	const { game } = useContext(GameContext)
	const { documents, system } = game
	const collections = useMemo(() => system?.collections || [], [system])
	const { userId, isGM } = useUser()

	const isDocumentAccessible = (document: IDocument) => {
		return (
			document.access === 'public' ||
			(Array.isArray(document.accessList) &&
				document.accessList.includes(userId))
		)
	}

	const collectionTypes = useMemo(() => {
		// Add 'Books' to the initial collection types
		const allTypes: CollectionTypeInfo[] = [
			{ type: 'Books', pluralName: 'Books' },
			...collections.map(c => ({ type: c.type, pluralName: c.pluralName })),
		]

		// GMs see all collections types (except folders)
		if (isGM) {
			return allTypes.filter(({ type }) => type !== 'folder')
		}

		// Players see all collection types that are public
		// or which contain documents they have access to
		const initialTypes = allTypes.filter(
			({ type }) =>
				type === 'Books' ||
				collections.find(c => c.type === type && c.allowCreate === 'true'),
		)

		const { allIds, byId } = documents
		const accessibleTypes: CollectionTypeInfo[] = []
		allIds.forEach(id => {
			const doc = byId[id]
			if (
				isDocumentAccessible(doc) &&
				!accessibleTypes.some(t => t.type === doc.type)
			) {
				const collectionInfo = initialTypes.find(t => t.type === doc.type)
				if (collectionInfo) {
					accessibleTypes.push(collectionInfo)
				}
			}
		})

		return accessibleTypes
	}, [collections, documents, isGM, userId])

	return collectionTypes
}

export default useCollectionTypes
