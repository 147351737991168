import { useContext } from 'react'
import GameContext from '../../contexts/game'
import Overlapper from '../Overlapper'
import ClearAllButton from './ClearAllButton'
import NextButtons from './NextButton'
import PreviousButtons from './PreviousButton'
import SortButton from './SortButton'
import StartStopButton from './StartStopButton'

const TurnTrackerControls = () => {
	const { game } = useContext(GameContext)
	const { turnOrder, currentRound } = game.turnTracker

	const hasActiveItems = turnOrder.allIds.some(
		id => turnOrder.byId[id].isActive,
	)
	const isRunning = currentRound > 0

	if (!turnOrder.allIds.length) return null

	return (
		<Overlapper className='mb-2 justify-center'>
			<ClearAllButton />
			<SortButton />
			<StartStopButton />
			<PreviousButtons disabled={!hasActiveItems || !isRunning} />
			<NextButtons disabled={!hasActiveItems || !isRunning} />
		</Overlapper>
	)
}

export default TurnTrackerControls
