import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import { twMerge } from 'tailwind-merge'
import GameContext from '../../contexts/game'
import useFrameUrl from '../../hooks/useFrameUrl'
import useGetAvatarSrc from '../../hooks/useGetAvatarSrc'
import useGetUserById from '../../hooks/useGetUserById'
import { DiceMessage } from '../../interfaces/chat'
import Avatar from '../Avatar'
import useHandleFrameLoad from '../document/useHandleFrameLoad'
import SmallUppercase from '../text/SmallUppercase'
import MessageMenu from './MessageMenu'

interface DiceRollMessageProps {
	message: DiceMessage
	onDelete: () => void
	showSender?: boolean
	className?: string
	style?: React.CSSProperties
}

const DiceRollMessage: React.FC<DiceRollMessageProps> = React.memo(
	({ message, onDelete, className, style, showSender }) => {
		const { game } = useContext(GameContext)
		const { user } = useGetUserById(message.sender)
		const [frameIsLoaded, setFrameIsLoaded] = useState(false)
		const [messageTarget, setMessageTarget] = useState<Window | null>(null)
		const frameUrl = useFrameUrl({ documentId: 'dice' })
		const [isInView, setIsInView] = useState(false)
		const containerRef = useRef<HTMLDivElement>(null)
		const avatarSrc = useGetAvatarSrc({
			userId: message.sender,
			assumedCharacterId: message.assumedCharacterId,
			fallbackAvatarId: message.fallbackAvatarId,
			disableAssumedCharacterLookup: false,
		})

		const name = useMemo(
			() =>
				user?.userProfile?.name ||
				`${message.fallbackName}${user ? '' : ' (removed)'}`,
			[user, message.fallbackName],
		)

		const iframeUrl = useMemo(
			() =>
				`${frameUrl}&dice=${encodeURIComponent(
					JSON.stringify(message.diceResults || {}),
				)}`,
			[frameUrl, message.diceResults],
		)

		const messageToFrame = useCallback(
			(message: string, bundle?: any) => {
				const data = bundle ? { ...bundle, id: 'dice' } : { id: 'dice' }
				const isNotReady = !messageTarget || !frameIsLoaded
				const messageIsNotOnLoad = message !== 'load'

				if (isNotReady && messageIsNotOnLoad) return

				const postMessage = {
					source: 'App',
					message,
					data,
				}

				messageTarget?.postMessage(postMessage, new URL(frameUrl).origin)
			},
			[messageTarget, frameIsLoaded, frameUrl],
		)

		const loadPayload = useMemo(
			() => ({
				documentId: 'dice',
				documentMode: 'view' as const,
				messageToFrame,
				setFrameIsLoaded,
			}),
			[messageToFrame],
		)

		const handleFrameLoad = useHandleFrameLoad(loadPayload)

		useEffect(() => {
			const observer = new IntersectionObserver(
				([entry]) => {
					setIsInView(entry.isIntersecting)
				},
				{
					root: null,
					rootMargin: '0px',
					threshold: 0.01,
				},
			)

			if (containerRef.current) {
				observer.observe(containerRef.current)
			}

			return () => {
				if (containerRef.current) {
					observer.unobserve(containerRef.current)
				}
			}
		}, [])

		useEffect(() => {
			if (!isInView) return

			const frameMessagingListener = (e: MessageEvent) => {
				const messageData = e?.data ?? {}
				const { message, id } = messageData ?? {}
				const originatingDocumentId = id ?? null
				const wrongOrigin = e.origin !== new URL(frameUrl).origin
				const wrongSource = messageData.source !== 'Aux'
				const wrongDocument = originatingDocumentId !== 'dice'

				if (wrongDocument || !messageTarget || wrongOrigin || wrongSource)
					return

				if (message === 'load') {
					handleFrameLoad()
				}
			}

			window.addEventListener('message', frameMessagingListener)
			return () => window.removeEventListener('message', frameMessagingListener)
		}, [frameUrl, messageTarget, handleFrameLoad, isInView])

		const supportsDiceMessageType = useMemo(
			() => game.system?.chatMessageTypes?.some(type => type.type === 'dice'),
			[game.system?.chatMessageTypes],
		)

		const containerClassName = useMemo(
			() =>
				twMerge(
					'max-w-4/6 message inline-block transition-all duration-200',
					showSender ? 'my-2' : 'mt-1',
					className,
				),
			[showSender, className],
		)

		const handleIframeLoad = useCallback(
			(e: React.SyntheticEvent<HTMLIFrameElement>) =>
				setMessageTarget(e.currentTarget.contentWindow),
			[],
		)

		return (
			<MessageMenu onDelete={onDelete}>
				<div
					id={message._id}
					className={containerClassName}
					style={style}
					ref={containerRef}
				>
					<div className='flex'>
						{showSender && <Avatar src={avatarSrc} />}
						<div className={!showSender ? 'ml-9' : ''}>
							{showSender && (
								<SmallUppercase className='ml-2 mb-1'>{name}</SmallUppercase>
							)}
							<div className='flex-grow'>
								{supportsDiceMessageType ? (
									isInView ? (
										<iframe
											src={iframeUrl}
											width='100%'
											height='150'
											onLoad={handleIframeLoad}
											className='w-full'
										/>
									) : (
										<div className='flex h-[150px] w-full items-center justify-center'>
											Loading dice roll...
										</div>
									)
								) : (
									<p className='my-2 ml-5 text-sm'>{message.message}</p>
								)}
							</div>
						</div>
					</div>
				</div>
			</MessageMenu>
		)
	},
)

DiceRollMessage.displayName = 'DiceRollMessage'

export default DiceRollMessage
