import { useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import GameContext from '../../contexts/game'
import useCollectionTypes from '../../hooks/useCollectionTypes'
import DocumentTypeListItem from './DocumentTypeListItem'

export default function DocumentTypeList() {
	const { game } = useContext(GameContext)
	const { library } = game
	const collectionTypes = useCollectionTypes()
	const { setValue } = useFormContext()

	const sortedCollectionTypes = collectionTypes.sort((a, b) =>
		a.type.localeCompare(b.type),
	)

	if (!library.show) return null

	return (
		<div className='mb-4 flex flex-wrap justify-center gap-x-0 gap-y-0.5'>
			{sortedCollectionTypes.map(({ type, pluralName }) => (
				<button key={type} onClick={() => setValue('selection', type)}>
					<DocumentTypeListItem label={pluralName} type={type} />
				</button>
			))}
		</div>
	)
}
