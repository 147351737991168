import { TrashIcon } from '@heroicons/react/24/solid'
import { useContext } from 'react'
import GameContext from '../../contexts/game'
import SectionButton from './SectionButton'

const ClearAllButton = () => {
	const { game, dispatch } = useContext(GameContext)
	const { currentRound } = game.turnTracker

	const handleClear = () => {
		dispatch({ type: 'CLEAR_TURN_TRACKER' })

		if (currentRound > 0) {
			dispatch({
				type: 'ADD_SYSTEM_MESSAGE',
				payload: {
					message: 'Turn tracking ended.',
				},
			})
		}
	}

	return (
		<SectionButton onClick={handleClear}>
			<TrashIcon className='h-4 w-4' />
		</SectionButton>
	)
}

export default ClearAllButton
