import { useContext, useEffect, useState } from 'react'
import { MemoryProvider } from '../../contexts/MemoryProvider'
import GameContext from '../../contexts/game'
import SessionStage from '../SessionStage/SessionStage'
import Sidebar from '../Sidebar'
import Chat from '../chat/Chat'
import DiceToolsWrapper from '../dice/DiceToolsWrapper'
import SceneTools from '../toolbar/SceneTools'
import GMTurnTracker from '../turnTracker/GMTurnTracker'
import NewPlayerTurnTracker from '../turnTracker/NewPlayerTurnTracker'
import UserHeads from '../users/UserHeads'
import GameName from './GameName'
import LeftTools from './LeftTools'
import RightTools from './RightTools'
import Scratchpad from './Scratchpad'

const Interface = () => {
	const { game } = useContext(GameContext)
	const [showGameName, setShowGameName] = useState(false)
	const [fadeOut, setFadeOut] = useState(false)

	useEffect(() => {
		const timer = setTimeout(() => {
			setFadeOut(true)
			const hideTimer = setTimeout(() => {
				setShowGameName(false)
			}, 1000) // Match the duration of the CSS transition
			return () => clearTimeout(hideTimer)
		}, 3000) // 3 seconds

		return () => clearTimeout(timer)
	}, [])

	return (
		<MemoryProvider>
			<div className='pointer-events-none fixed inset-4 z-30 flex flex-row gap-4 text-gray-300'>
				{/* <div className='flex flex-1'> */}
				<div
					className='pointer-events-auto flex flex-col @container'
					style={{ flex: '0 0 360px', width: '360px' }}
				>
					<LeftTools />
					<Scratchpad />
					<Chat />
				</div>

				<div className='pointer-events-none flex flex-auto flex-col justify-between'>
					{game.turnTracker.currentRound === 0 ? (
						<UserHeads />
					) : (
						<NewPlayerTurnTracker />
					)}

					<div className='justify-end self-center'>
						<SceneTools />
						<DiceToolsWrapper />
					</div>
				</div>

				<div
					className='pointer-events-auto flex h-full flex-col'
					style={{ flex: '0 0 360px', width: '360px' }}
				>
					<RightTools />
					{/* <Syrinscape /> */}
					<GMTurnTracker />
					<SessionStage />
					<Sidebar />
				</div>
				{/* </div> */}

				{/* <Toolbelt /> */}
			</div>

			{showGameName && (
				<div
					className={`pointer-events-none fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 transition-opacity duration-1000 ${
						fadeOut ? 'opacity-0' : 'opacity-100'
					}`}
				>
					<GameName />
				</div>
			)}
		</MemoryProvider>
	)
}

export default Interface
