import { useContext, useEffect, useState } from 'react'
import AuthContext from '../../contexts/auth'
import DocumentsContext from '../../contexts/documents'
import GameContext from '../../contexts/game'
import useTheme from '../../hooks/useTheme'
import useUser from '../../hooks/useUser'
import { IScene } from '../../interfaces/scene'

export default function SceneName() {
	const { game } = useContext(GameContext)
	const { primary, secondary } = useTheme()
	const { authState } = useContext(AuthContext)
	const { userId } = authState
	const { isGM } = useUser()

	const [scene, setScene] = useState<IScene>(null)
	const [sceneName, setSceneName] = useState('Untitled Scene')
	const [sceneSubtitle, setSceneSubtitle] = useState('')
	const [nameIsSecret, setNameIsSecret] = useState<boolean>(false)
	const { activeSceneId } = game.userSettings[userId]
	const activeScene = game.documents.byId[activeSceneId] as IScene
	const { dispatchDocuments } = useContext(DocumentsContext)

	useEffect(() => {
		setScene(activeScene)
		setSceneName(activeScene?.values?.name || 'Untitled Scene')
		setSceneSubtitle(activeScene?.values?.subtitle || '')
		setNameIsSecret(activeScene?.values?.nameIsSecret)
	}, [game.documents, game.userSettings, userId])

	const handleSceneSettingsClick = () => {
		dispatchDocuments({
			type: 'OPEN_DOCUMENT',
			payload: { documentId: scene?._id },
		})
	}

	if (!scene || (nameIsSecret && !isGM)) return null

	return (
		<div
			className='mb-2 text-center font-bold uppercase tracking-wider text-gray-200'
			style={{
				fontFamily: 'Rubik, sans-serif',
				textShadow: '0 4px 14px rgba(0,0,0,0.4), 0 0 2px rgba(0,0,0,0.1)',
			}}
		>
			{isGM ? (
				<>
					<p
						className='mx-20 cursor-pointer text-3xl tracking-wide hover:underline'
						style={{ color: primary }}
						onClick={handleSceneSettingsClick}
					>
						{nameIsSecret ? `(${sceneName})` : sceneName}
					</p>
					{sceneSubtitle && (
						<p style={{ color: secondary }}>
							{nameIsSecret ? `(${sceneSubtitle})` : sceneSubtitle}
						</p>
					)}
				</>
			) : (
				<>
					<p
						className='mx-20 text-3xl tracking-wide'
						style={{ color: primary }}
					>
						{nameIsSecret ? 'Unnamed Scene' : sceneName}
					</p>
					{!nameIsSecret && sceneSubtitle && (
						<p style={{ color: secondary }}>{sceneSubtitle}</p>
					)}
				</>
			)}
		</div>
	)
}
