// this component handles loading and syncing the game state with the server
// when the client first connects to the server
// clients send patches to the server when the game state changes
// the server sends patches to the client when the game state changes
// clients can also request a backfill of patches when they're behind
import { Patch, produceWithPatches } from 'immer'
import _ from 'lodash'
import { useCallback, useContext, useEffect, useRef } from 'react'
import {
	RequestBackfillMessage,
	UpdateMessage,
} from '../../../shared/types/socket'
import AuthContext from '../contexts/auth'
import GameContext from '../contexts/game'
import { socket } from '../contexts/socket'
import { IGame } from '../interfaces/game'
import { IUser } from '../interfaces/users'

const useSyncPatches = () => {
	const { game, dispatch } = useContext(GameContext)
	const { authState } = useContext(AuthContext)
	const { userId } = authState
	const previousGameStateRef = useRef(game)
	const gameIsLoaded = useRef(false)
	const localVersion = useRef(0)
	const doNotUpdate = useRef(false)
	const isDebugging = false

	useEffect(() => {
		const handlePatches = ({
			originSocketId,
			version,
			patches,
		}: UpdateMessage & { version: number }) => {
			isDebugging &&
				console.log('Received patches from server', {
					originSocketId,
					version,
					patches,
				})
			if (originSocketId === socket.id) {
				// These patches originated from this user, ignore them, but set the local version
				isDebugging &&
					console.log('Received my own patches from server, ignoring them', {
						version,
					})
				localVersion.current = version
				return
			}
			if (version <= localVersion.current) {
				isDebugging &&
					console.log('Received patches from server, but version is too old', {
						version,
						localVersion: localVersion.current,
					})
				// Ignore patches older than the current version
				return
			} else if (version > localVersion.current + 1) {
				isDebugging &&
					console.log(
						'Received patches from server, but version is too new, requesting backfill...',
						{
							version,
							localVersion: localVersion.current,
						},
					)
				// if version is more than 1 ahead, request backfill
				const message: RequestBackfillMessage = {
					fromVersion: localVersion.current,
				}
				socket.emit('requestBackfill', message)
			} else {
				isDebugging &&
					console.log('Received patches from server', { patches, version })
				doNotUpdate.current = true
				dispatch({ type: 'APPLY_PATCHES', patches })
				localVersion.current = version
			}
		}

		type TPatchesArray = { patches: Patch[]; version: number }[]

		const handleBackfillPatches = (patchesArray: TPatchesArray) => {
			isDebugging && console.log('Handling backfill patches', { patchesArray })
			if (!patchesArray) return
			patchesArray.sort((a, b) => a.version - b.version)
			// Ensure patches are sorted by version.

			// Iterate through patchesArray and apply each group of patches in order
			patchesArray.forEach(patchInfo => {
				const { version, patches } = patchInfo
				// Consider checking if each patch is applicable by comparing the version
				// if (version === localVersion.current + 1) {
				dispatch({ type: 'APPLY_PATCHES', patches: patches })
				localVersion.current = version
				// }
			})
		}

		const handleLoadData = (data: IGame) => {
			if (gameIsLoaded.current) return
			console.log('Loading data from server', data)
			dispatch({ type: 'UPDATE_STATE', payload: { game: data } })
			localVersion.current = data.version
			gameIsLoaded.current = true
		}

		const handleError = (error: string) => {
			console.error('Error from server:', error)
			alert(error)
		}

		const handleUpdateUserData = (data: IUser) => {
			console.log('Updating user data from server', data)

			// Check if game.users is an array of strings
			if (
				Array.isArray(game.users) &&
				game.users.every(user => typeof user === 'string')
			) {
				// Generate patches that represent the differences between the new user data and the existing user data
				const [, patches] = produceWithPatches(
					game.users,
					(draft: string[]) => {
						const userIndex = draft.findIndex(
							(userId: string) => userId === data._id,
						)
						if (userIndex !== -1) {
							draft[userIndex] = data._id
						}
					},
				)

				// Apply the patches to the client's game state
				dispatch({ type: 'APPLY_PATCHES', patches })

				// Assuming data has a version property
				if ('version' in data && typeof data.version === 'number') {
					localVersion.current = data.version
				}
			} else {
				console.error('game.users is not an array of strings')
			}
		}

		socket.on('patches', handlePatches)
		socket.on('backfillPatches', handleBackfillPatches)
		socket.on('load data', handleLoadData)
		socket.on('updateUserData', handleUpdateUserData)
		socket.on('error', handleError)

		// Clean up event listeners when the component unmounts
		return () => {
			socket.off('patches', handlePatches)
			socket.off('backfillPatches', handleBackfillPatches)
			socket.off('load data', handleLoadData)
			socket.off('updateUserData', handleUpdateUserData)
			socket.off('error', handleError)
		}
	}, [])

	const sendPatchesToServer = useCallback(
		(patches: Patch[]) => {
			isDebugging &&
				console.log('Sending patches to server', {
					originSocketId: socket.id,
					patches,
				})
			if (!userId) return
			const updateMessage: UpdateMessage = {
				originSocketId: socket.id,
				patches,
			}
			socket.emit('update', updateMessage)
		},
		[game],
	)

	const throttledSendPatchesToServer = useCallback(
		_.throttle(sendPatchesToServer, 500),
		[],
	)

	useEffect(() => {
		const handleReconnect = () => {
			console.log('Reconnected to the server, requesting backfill...')
			const message: RequestBackfillMessage = {
				fromVersion: localVersion.current,
			}
			socket.emit('requestBackfill', message)
		}

		socket.on('reconnect', handleReconnect)

		return () => {
			socket.off('reconnect', handleReconnect)
		}
	}, [localVersion])

	useEffect(() => {
		// Don't send updates until after the game is loaded
		if (!gameIsLoaded.current) return

		// do not update if patches were received from server
		if (doNotUpdate.current) {
			doNotUpdate.current = false
			return
		}

		// use immer to get the difference between the
		// previous game state and the current game state
		const [, patches] = produceWithPatches(
			previousGameStateRef.current,
			draft => {
				// patches based on the difference
				Object.assign(draft, game)
			},
		)

		// we do not want to send patches with the system
		// because the system can be larger than sockets can bear
		// so we save and load the system separately
		const filteredPatches = patches.filter(
			patch => !patch.path.includes('system'),
		)

		doNotUpdate.current = true
		dispatch({ type: 'UPDATE_VERSION', payload: game.version + 1 })
		localVersion.current = game.version + 1

		if (
			filteredPatches.length > 0 &&
			!_.isEqual(previousGameStateRef.current, game)
		) {
			throttledSendPatchesToServer(filteredPatches)
			previousGameStateRef.current = game
		}
	}, [game, throttledSendPatchesToServer])

	useEffect(() => {
		const handleDisconnect = (reason: string) => {
			console.warn(`Socket disconnected: ${reason}`)
		}

		const handleConnectError = (error: Error) => {
			console.error('Connection error:', error)
		}

		socket.on('disconnect', handleDisconnect)
		socket.on('connect_error', handleConnectError)

		return () => {
			socket.off('disconnect', handleDisconnect)
			socket.off('connect_error', handleConnectError)
		}
	}, [])
}

export default useSyncPatches
