import { useContext, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import GameContext from '../../contexts/game'
import useTheme from '../../hooks/useTheme'
import Avatar from '../Avatar'
import Overlapper from '../Overlapper'

const NewPlayerTurnTracker = () => {
	const { game } = useContext(GameContext)
	const { currentRound, turnOrder, currentTurnIndex } = game?.turnTracker || {}
	const { primary, secondary } = useTheme()
	const [hoveredName, setHoveredName] = useState<string | null>(null)

	// Render nothing if there's no current round or turn order
	if (!currentRound || !turnOrder?.allIds.length) {
		return null
	}

	const participants = turnOrder.allIds.map((id, index) => {
		const participant = turnOrder.byId[id]
		const isCurrentTurn = index === currentTurnIndex
		const isActive = participant.isActive
		const name = participant.identified ? participant.name : 'Unidentified'
		const document = game.documents.byId[participant.documentId]
		const avatarAssetId = document?.values?.portrait || document?.values?.token
		const avatar = game.assets.byId[avatarAssetId]
		const avatarUrl = avatar?.fileurl || ''

		console.log(avatarUrl)

		return {
			id,
			name,
			isCurrentTurn,
			isActive,
			avatarUrl: avatarUrl || '',
		}
	})

	return (
		<div className='pb-0'>
			{/* <SectionDivider
				label={`Turn Tracker (Round ${currentRound})`}
				className='my-4'
			/> */}

			<Overlapper
				className='mt-2 space-x-2'
				selectedIndex={currentTurnIndex}
				hoverScale={1.1}
				align='center'
			>
				{participants.map(participant => (
					<div
						key={participant.id}
						className={`relative ${participant.isActive ? '' : 'opacity-50'}`}
						title={participant.name}
						onMouseEnter={() => setHoveredName(participant.name)}
						onMouseLeave={() => setHoveredName(null)}
					>
						<Avatar
							src={participant.avatarUrl}
							className={twMerge(
								'aspect-[3/4] h-24 transform outline outline-2 transition-transform duration-300 ease-in-out',
								participant.isCurrentTurn ? 'scale-110' : 'scale-100',
							)}
							style={{
								transformOrigin: 'center 75%',
								outlineColor: participant.isCurrentTurn
									? primary
									: 'transparent',
							}}
						/>
						{participant.isCurrentTurn && (
							<div
								className='absolute -bottom-1 left-1/2 h-1 w-4 -translate-x-1/2 rounded-full'
								style={{ backgroundColor: secondary }}
							></div>
						)}
					</div>
				))}
			</Overlapper>

			<div className='mt-2 text-center text-sm'>
				<div className='font-semibold' style={{ color: primary }}>
					{hoveredName || participants[currentTurnIndex]?.name}
				</div>
				<div className='font-semibold' style={{ color: secondary }}>
					Round {currentRound}
				</div>
			</div>
		</div>
	)
}

export default NewPlayerTurnTracker
